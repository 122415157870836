import ClipboardJS from 'clipboard'

const init = (event) => {

    const clipboard = new ClipboardJS('.clipboard-copy');

    clipboard.on('success', function (e) {
        bootstrap.Tooltip.getInstance(e.trigger).setContent({'.tooltip-inner': 'Copied'})
        e.clearSelection();
    });

    clipboard.on('error', function (e) {
        console.info('clipboard error');
        console.log(e);
    });

    const els = document.querySelectorAll('.clipboard-copy');
    const text = 'Copy to clipboard';

    [...els].forEach(el => {
        const tooltip = new bootstrap.Tooltip(el, {'title': text})
        tooltip.setContent({'.tooltip-inner': text})
        el.addEventListener('hidden.bs.tooltip', (ev) => {
            tooltip.setContent({'.tooltip-inner': text})
        })
    })

}

document.addEventListener('htmx:afterSettle', init)

window.addEventListener('DOMContentLoaded', init)
