import * as htmx from 'htmx.org';
import * as bootstrap from 'bootstrap'

const toastElList = document.querySelectorAll('.toast');
const toastList = [...toastElList].map(toastEl => new bootstrap.Toast(toastEl, {}));

const toast = toastList[0]
const body = toastElList[0].querySelector('.toast-body')

// Show message in toast
htmx.on('showMessage', function (evt) {
    showToast(evt.detail.value);
});

export function showToast(message) {
    body.innerHTML = message;
    toast.show();
}
