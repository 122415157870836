import * as htmx from 'htmx.org';

// Close modal after upload
htmx.on('closeResendVerificationModal', function (evt) {
    htmx.find('#resendVerificationModalClose').click();
});

// Disable submit button
htmx.on('#resendVerificationForm', 'htmx:beforeRequest', function (evt) {
    evt.target.elements['submit'].disabled = true;
});
