import '../scss/styles.scss'

import * as Sentry from "@sentry/browser";
import {debugUser} from '../../copilot/templates/include/debug_user'
Sentry.init({
    dsn: window.location.host === "sidekick.yung.app" ? "https://431cc9afd7e14e2cb540eba103d00870@o1361682.ingest.sentry.io/4505487486681088" : "https://388e39119e126a11726cba4f3770b6f6@o1361682.ingest.sentry.io/4505657635438592",

    // Alternatively, use `process.env.npm_package_version` for a dynamic release version
    // if your build tool supports it.
    // release: "my-project-name@2.3.12",
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: debugUser() ? 1.0 : 0.1,
    replaysOnErrorSampleRate: 1.0,
});



import * as bootstrap from 'bootstrap'
import * as htmx from 'htmx.org';
import {logEvent} from "../../copilot/templates/include/log_event";

window.bootstrap = bootstrap;
window.htmx = htmx;
window.logEvent = logEvent;

import '../../copilot/templates/include/clipboard-copy'
import '../../copilot/templates/include/invite_friends_modal'

import '../../copilot/templates/copilot/main/include/select_client'

const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]')
const tooltipList = [...tooltipTriggerList].map(tooltipTriggerEl => new bootstrap.Tooltip(tooltipTriggerEl))

import '../../copilot/templates/include/resend_verification_modal'
import {showToast} from '../../copilot/templates/include/init_toast'
window.showToast = showToast

import confirmModal from '../../copilot/templates/include/confirm_modal'


document.addEventListener("htmx:confirm",  (e) =>{
    e.preventDefault()

    if (e.detail.question === null) {
        e.detail.issueRequest(true)
        return false;
    }

    const options = {
        "title": e.detail.question,
        "body": e.target.getAttribute("hx-confirm-body"),
        "action": e.target.getAttribute("hx-confirm-action"),
        "danger": e.target.getAttribute("hx-confirm-danger") === "true"
    }

    confirmModal(options).then( (result) => {
        e.detail.issueRequest(true)
    })

})
