// Show/hide new client name in session creation forms

const els = document.querySelectorAll('.form-select-client');

[...els].forEach(el => {
    el.addEventListener('change', (evt) => {
        const containerClassList = document.getElementById(el.dataset.newClientId + '__container').classList
        if (evt.target.value) {
            containerClassList.add('d-none')
            document.getElementById(el.dataset.newClientId).value = '';
        } else {
            containerClassList.remove('d-none')
        }
    })
})
