import * as bootstrap from 'bootstrap'

const modal_el = document.getElementById('js-confirm-modal')
const modal = new bootstrap.Modal(modal_el)

const title = modal_el.querySelector('#js-confirm-modal-title')
const body = modal_el.querySelector('#js-confirm-modal-body')
const button = modal_el.querySelector('#js-confirm-modal-confirm')

export default function confirmModal (options?: object): Promise<boolean> {
    if (options) {
        title.textContent = options['title'] ? options['title'] : "Confirm?"
        body.textContent = options['body'] ? options['body'] : ""
        button.textContent = options['action']? options['action'] : "Confirm"
        if (options['danger']) {
            button.classList.add('text-danger')
        } else {
            button.classList.remove('text-danger')
        }
    }

    return new Promise<boolean>((resolve, reject) => {
        modal.show()
        const confirm = () => {
            resolve(true)
            modal.hide()
        }

        button.addEventListener('click', confirm, { once: true })
        modal_el.addEventListener('hide.bs.modal', () => {
            button.removeEventListener('click', confirm)
        })
    })
}
