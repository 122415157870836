window.addEventListener('DOMContentLoaded', (event) => {

    const textarea = document.getElementById('refLinkText')
    const wa_link = document.getElementById('inviteLinkWa')
    const gmail_link = document.getElementById('inviteLinkGmail')
    const mailto_link = document.getElementById('inviteLinkMailto')
    const subject = encodeURIComponent('Join Yung Sidekick')

    function updateLinks(body) {
        const body_url = encodeURIComponent(body)
        gmail_link.href = `https://mail.google.com/mail/?view=cm&fs=1&su=${subject}&body=${body_url}`
        mailto_link.href = `mailto:?cc=aa@yung.app&subject=${subject}&body=${body_url}`
        wa_link.href = `https://wa.me/?text=${body_url}`
    }

    updateLinks(textarea.value)

    textarea.addEventListener('keyup', (ev) => {
        updateLinks(textarea.value)
    })

})
